<script>
import HelexiumForm from "../../../components/HelexiumForm/HelexiumForm.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    HelexiumForm,
  },
  async mounted() {
    const email = JSON.parse(localStorage.setPersonaAct).correo;
    const userLogged = await this.$store.dispatch("get", {
      path: `Persona/GetPersonaRecuperar/${email}`,
    });
    const data = await this.$store.dispatch("get", {
      path: `Sucursal/ListSucursalEmpresa/${userLogged.empresaId}`,
    });
    const sucursales = data.map((sucursal) => {
      return {
        value: sucursal.id,
        label: sucursal.descripcion,
      };
    });

    this.schema = [
      {
        name: "Placa",
        value: "",
        label: "Placa",
        placeholder: "ABC123",
        type: "text",
        validations: "required|length:6,6",
        state: "",
      },
      {
        name: "SucursalId",
        value: "",
        label: "Asignar sucursal al vehículo",
        type: "select",
        validations: "required",
        options: sucursales,
      },
    ];
  },
  data() {
    return {
      schema: null,
      vehiculo: null,
      notExistVehiculo: false,
    };
  },
  methods: {
    buscarPlaca(placa) {
      placa = placa.toUpperCase();
      this.$store
        .dispatch("get", { path: `Vehiculo/Placa/${placa}` })
        .then((result) => {
          return new Promise((resolve, reject) => {
            result = result.traceId ? null : result;
            if (result) {
              resolve(result);
            } else {
              reject("Vehículo no encontrado");
            }
          });
        })
        .then((result) => {
          this.schema[0].state = "succes";
          this.schema[0].errorMessage = null;
          this.notExistVehiculo = false;
          this.vehiculo = result;
          console.log(result);
        })
        .catch((error) => {
          this.notExistVehiculo = true;
          this.schema[0].state = "error";
          this.schema[0].errorMessage = error;
          this.vehiculo = { placa };
        });
    },
    programingBuscarPlaca({ name, validation, value: placa }) {
      if (name == "Placa") {
        this.schema[0].state = "";
        this.schema[0].errorMessage = null;
        if (validation == 0) {
          this.buscarPlaca(placa);
        }
      }
    },

    crearVehiculo(model, isValidated) {
      if (isValidated) {
        model.Placa = model.Placa.toUpperCase();
        // Mostrar alertas de que todo ha salido bien
        this.$store
          .dispatch("post", { path: `Vehiculo`, data: model })
          .then((result) => {
            return new Promise((resolve, reject) => {
              result = result.traceId ? null : result;
              if (result) {
                resolve(result);
              } else {
                reject("No se ha podido crear el vehiculo");
              }
            });
          })
          .then((result) => {
            Swal.fire({
              title: "Vehiculo creado correctamente",
              text: `El vehiculo con placa ${result.Placa} se ha creado correctamente`,
              icon: "success",
              confirmButtonText: "Aceptar",
            });
            this.initModelVue();
          })
          .catch((error) => {
            Swal.fire({
              title: "Ha ocurrido un error",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
      }
    },

    actualizarVehiculo(model, isValidated) {
      if (isValidated) {
        model.Placa = model.Placa.toUpperCase();
        this.$store.getters
          .fetchPut({ path: `Vehiculo/${this.vehiculo.id}`, data: model })
          .then((response) => {
            if (response.status == 200) {
              Swal.fire({
                title: "Vehiculo actualizado correctamente",
                text: `El vehiculo con placa ${model.Placa} se ha actualizado correctamente`,
                icon: "success",
                confirmButtonText: "Aceptar",
              });
              this.initModelVue();
            } else {
              Swal.fire({
                title: "Ha ocurrido un error",
                text: "No se ha podido actualizar el vehiculo",
                icon: "error",
                confirmButtonText: "Aceptar",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async eliminarVehiculo(model, isValidated) {
      if (isValidated) {
        if (model.SucursalId != this.vehiculo.sucursalId) {
          Swal.fire({
            title: "Ha ocurrido un error",
            text: "No se puede eliminar un vehiculo que no pertenece a la sucursal seleccionada",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          return;
        } else {
          this.$store.getters
            .fetchDelete({ path: `Vehiculo/${this.vehiculo.id}` })
            .then((response) => {
              if (response.status == 200) {
                Swal.fire({
                  title: "Vehiculo eliminado correctamente",
                  text: `El vehiculo con placa ${this.vehiculo.placa} se ha eliminado correctamente`,
                  icon: "success",
                  confirmButtonText: "Aceptar",
                });
                this.initModelVue();
              } else {
                Swal.fire({
                  title: "Ha ocurrido un error",
                  text: "No se ha podido eliminar el vehiculo",
                  icon: "error",
                  confirmButtonText: "Aceptar",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    initModelVue() {
      const aux = [
        ...this.schema.map((item) => {
          item.value = "";
          return item;
        }),
      ];
      this.schema = null;
      this.$nextTick(() => {
        this.schema = aux;
      });

      this.vehiculo = null;
      this.notExistVehiculo = false;
    },
  },
  computed: {
    vehiculoLabel() {
      return this.schema[1].options.find(
        (sucursal) => sucursal.value == this.vehiculo.sucursalId
      ).label;
    },
  },
};
</script>

<template>
  <HXContentCard title="Registro de Vehiculos">
    <card class="py-4 px-4">
      <!-- Crear nuevo registro: el input de placa puede ser un aturo completado -->
      <!-- Actualizar registro -->
      <!-- cuando se realice la validacion en placa vehículo hacer una búsqueda programática y buscar un resumen -->
      <HelexiumForm
        v-if="schema"
        :schema="schema"
        :observer="['Placa']"
        @watcher="programingBuscarPlaca"
      >
        <template #submit="{ data, isValidated }" class="d-flex">
          <div class="d-flex justify-content-start">
            <vs-button
              animation-type="vertical"
              icon
              success
              @click.prevent="crearVehiculo(data, isValidated)"
              :disabled="!isValidated || !notExistVehiculo"
              ><i class="fas fa-plus px-1"></i>Crear<template #animate
                ><i class="fas fa-plus"></i></template
            ></vs-button>
            <vs-button
              animation-type="vertical"
              icon
              @click.prevent="actualizarVehiculo(data, isValidated)"
              :disabled="!isValidated || notExistVehiculo"
              ><i class="fa-solid fa-arrows-rotate pr-1"></i>Actualizar<template
                #animate
                ><i class="fa-solid fa-arrows-rotate"></i></template
            ></vs-button>
            <vs-button
              animation-type="vertical"
              icon
              danger
              @click.prevent="eliminarVehiculo(data, isValidated)"
              :disabled="!isValidated || notExistVehiculo"
              ><i class="fa-solid fa-xmark pr-1"></i>Eliminar<template #animate
                ><i class="fa-solid fa-xmark"></i></template
            ></vs-button>
          </div>
        </template>
      </HelexiumForm>

      <div>
        <b-list-group v-if="vehiculo" class="mt-3">
          <b-list-group-item v-if="notExistVehiculo">
            No existe un vehículo con la placa {{ vehiculo.placa }}
          </b-list-group-item>
          <b-list-group-item v-else>
            <p>Placa: {{ vehiculo.placa }}</p>
            <p>Sucursal relacionada {{ vehiculoLabel }}</p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </card>
  </HXContentCard>
</template>
